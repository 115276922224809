import "tailwindcss/dist/base.css";
import React from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import logo from "../assets/images/Uclusion_Wordmark_Color.svg";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import useAnimatedNavToggler from "./helpers/useAnimatedNavToggler";

const Header = tw.div`
z-10
flex justify-between mt-0
bg-white
w-full
top-0 left-0
fixed
bg-green-500
`;

export const NavLinks = tw.div`flex items-center justify-start`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a`
  lg:text-sm lg:mx-6 
  font-normal tracking-wide transition duration-300
  border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

const PrimaryButtonBase = tw.button`px-1 py-1 text-primary-500 border-3 border-primary-500 border-solid hover:bg-primary-500 hover:text-gray-100 focus:outline-none`;
const PrimaryButton = tw(
    PrimaryButtonBase,
)`font-bold inline-block mr-2 rounded-xl`;


export const LogoLink = styled(NavLink)`
  ${tw`inline-block font-black border-b-0 -mt-2 text-2xl! ml-0!`};
  img {
    ${tw`w-10`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

const SiteHeader = () => {
  /*
   * This header component accepts an optionals "links" prop that specifies the links to render in the navbar.
   * This links props should be an array of "NavLinks" components which is exported from this file.
   * Each "NavLinks" component can contain any amount of "NavLink" component, also exported from this file.
   * This allows this Header to be multi column.
   * So If you pass only a single item in the array with only one NavLinks component as root, you will get 2 column header.
   * Left part will be LogoLink, and the right part will be the the NavLinks component you
   * supplied.
   * Similarly if you pass 2 items in the links array, then you will get 3 columns, the left will be "LogoLink", the center will be the first "NavLinks" component in the array and the right will be the second "NavLinks" component in the links array.
   * You can also choose to directly modify the links here by not passing any links from the parent component and
   * changing the defaultLinks variable below below.
   * If you manipulate links here, all the styling on the links is already done for you. If you pass links yourself though, you are responsible for styling the links or use the helper styled components that are defined here (NavLink)
   */
  const defaultLinks = [
    <NavLinks>
      <NavLink href="https://production.uclusion.com" tw="lg:ml-12!">
        Login
      </NavLink>
      <PrimaryButton style={{marginRight: '1rem'}} href="https://production.uclusion.com/#signup">
        Sign Up
      </PrimaryButton>
    </NavLinks>
  ];

  const logoLinks = [
      <NavLinks>
        <LogoLink href="/">
          <img src={logo} alt="logo" style={{width: '150px'}} />
        </LogoLink>
       </NavLinks>
  ]

  const siteLinks = [
      <NavLinks>
        <NavLink href="https://blog.uclusion.com">Blog</NavLink>
        <NavLink href="/#pricing">Pricing</NavLink>
        <NavLink href="https://documentation.uclusion.com">Documentation</NavLink>
      </NavLinks>
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss = collapseBreakPointCssMap['lg'];

  function scrollLess(event) {
    event.preventDefault();
    toggleNavbar();
    const element = document.getElementById("pricing");
    // Need to compensate for the zoom on mobile
    window.scrollTo({
      behavior: 'smooth',
      top: element.getBoundingClientRect().top - 70,
    });
  }

  const siteLinksMobile = [
    <NavLinks>
      <NavLink href="https://blog.uclusion.com">Blog</NavLink>
      <NavLink href="/#pricing" onClick={scrollLess}>Pricing</NavLink>
      <NavLink href="https://documentation.uclusion.com">Documentation</NavLink>
    </NavLinks>
  ];


  return (

      <Header>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLinks}
        {siteLinks}
        {defaultLinks}
      </DesktopNavLinks>

      <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
        {logoLinks}
        <MobileNavLinks initial={{ x: "150%", display: "none" }} animate={animation} css={collapseBreakpointCss.mobileNavLinks}>
          {siteLinksMobile}
          {defaultLinks}
        </MobileNavLinks>
        <NavToggle onClick={toggleNavbar} className={showNavLinks ? "open" : "closed"}>
          {showNavLinks ? <CloseIcon tw="w-6 h-6" /> : <MenuIcon tw="w-6 h-6 mr-3" />}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  }
};


export default SiteHeader;