import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";

const Container = tw.div`content-center`;
const SectionDescription = tw.p`mt-4 text-sm md:text-base lg:text-lg font-medium leading-relaxed text-primary-500 max-w-xl`;
const SectionHeading = tw.h2`text-4xl sm:text-5xl font-black tracking-wide text-center`
const HeaderContainer = tw.div`flex flex-col items-center`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;
const PlansContainer = tw.div`flex flex-col justify-items-stretch md:justify-center md:flex-row items-center md:items-stretch relative mb-20`;
const Plan = styled.div`
  ${tw`w-full max-w-72 mt-12 md:mr-12 md:last:mr-0 text-center px-8 rounded-lg relative text-primary-500 bg-white flex flex-col shadow-raised`}

  ${props =>
    props.featured &&
    css`
      ${tw`border-2 border-gray-200 shadow-none`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col leading-relaxed py-4 -mx-8 bg-gray-100 rounded-t-lg`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-3xl sm:text-5xl`}
  }
  .duration {
    ${tw`lowercase text-gray-600 font-medium text-sm tracking-widest mb-2`}
  }
  .mainFeature {
    ${tw`text-gray-600 text-sm font-medium tracking-wide`}
  }
`;

const PlanAction = tw.div`px-4 pb-8`;
const PrimaryButtonBase = tw.button`px-1 py-1 text-primary-500 border-3 border-primary-500 border-solid hover:bg-primary-500 hover:text-gray-100 focus:outline-none`;
const PrimaryButton = tw(
    PrimaryButtonBase,
)`mt-4 text-2xl font-bold inline-block mr-2 rounded-xl`;


const Pricing =  ({
  heading = "Experience Uclusion risk free",
  description = "No setup, no feature limits.",
}) => {
  const defaultPlans = [
      {
      name: "Single subscriber",
      price: 'Free',
      duration: 'No credit card',
      buttonText: 'Start Now',
      buttonUrl: `https://production.uclusion.com/?utm_campaign=small}#signup`,
      mainFeature: "Unlimited observers",
      },
    {
      name: "Team",
      price: "$1",
      duration: 'per subscriber after 6 months',
      mainFeature: "Max $9/month",
      buttonText: 'Start Now',
      buttonUrl: `https://production.uclusion.com/?utm_campaign=small}#signup`
    }
  ];

  return (
    <Container id='pricing'>
        <HeaderContainer>
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeaderContainer>
        <PlansContainer>
          {defaultPlans.map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              <PlanHeader>
                  <div>
                      <div className="price">{plan.price}</div>
                      <div className="duration">{plan.duration}</div>
                  </div>
                  <span className="name">{plan.name}</span>
                  <span className="mainFeature">{plan.mainFeature}</span>
              </PlanHeader>
              {plan.buttonText &&
                (<PlanAction>
                    <PrimaryButton as="a" href={plan.buttonUrl}>
                        {plan.buttonText}
                    </PrimaryButton>
                </PlanAction>)}
            </Plan>
          ))}
        </PlansContainer>
    </Container>
  );
};

export default Pricing;