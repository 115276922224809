import React, {useState} from "react";
import tw from "twin.macro";
import ProgressMobile from "../assets/images/progressMobile.png";
import Progress from "../assets/images/progress.png";
import StatusMobile from "../assets/images/swimlanesMobile.png";
import StatusAutonomous from "../assets/images/statusAutonomous.png";
import Bugs from "../assets/images/bugs.png";
import Mobile from "../assets/images/mobile.jpg";
import JobApprovalMobile from "../assets/images/jobApprovalMobile.png";
import ApprovalsLarge from "../assets/images/approvalsLarge.png";
import QuestionMobile from "../assets/images/questionMobile.png";
import Question from "../assets/images/question.png";
import AssignJobMobile from "../assets/images/assignJobMobile.png";
import AssignJob from "../assets/images/jobAssignment.png";
import Unfurl from "../assets/images/slack-unfurl.png";
import BugsMobile from "../assets/images/bugsMobile.png";
import Discussion from "../assets/images/discussion.png";
import Tasks from "../assets/images/tasks.png";
import TasksMobile from "../assets/images/tasksMobile.png";
import Navigate from "../assets/images/navigate.png";

const ScalableContainer = tw.div`flex justify-center`;
const LeftNav = tw.div`flex-col mt-10 w-1/5`;
const CTAText = tw.div`text-xl md:text-2xl mr-4 ml-6`;
const RightPageContents = tw.div`min-h-144`;
export const TabLink = tw.a`
  text-2xl tracking-wide transition duration-300 cursor-pointer
  border-b-2 hover:border-black hover:border-primary-500 text-primary-500
`;
export const SelectedTab = tw.div`
  text-2xl tracking-wide transition duration-300 
`;
const ButtonContainer = tw.div`w-full mt-10`;
const SecondaryButton = tw.button`px-1 py-1 mt-2 mb-1 border-3 border-primary-500 border-solid text-xl font-bold inline-block rounded-xl hover:bg-primary-500 hover:text-gray-100 bg-white`;
const PrimaryButtonBase = tw.button`px-1 py-1 text-primary-500 border-3 border-primary-500 border-solid hover:bg-primary-500 hover:text-gray-100 focus:outline-none bg-white`;
const PrimaryButton = tw(
    PrimaryButtonBase,
)`mt-2 mb-1 text-xl font-bold inline-block rounded-xl`;
const primaryButtonUrl = "https://production.uclusion.com/?utm_campaign=team#signup";

const BelowTheFold = (properties) => {
    const { isMobile } = properties;
    const [visibleSection, setVisibleSection] = useState('solo');
    const [visiblePage, setVisiblePage] = useState('tasks');
    const ScrumButton = visiblePage === 'scrum' ? SelectedTab : TabLink;
    const SlackButton = visiblePage === 'slack' ? SelectedTab : TabLink;
    const InboxButton = visiblePage === 'inbox' ? SelectedTab : TabLink;
    const ScaleButton = visiblePage === 'scale' ? SelectedTab : TabLink;
    const SoloButton = visiblePage === 'solo' ? SelectedTab : TabLink;
    const IssuesButton = visiblePage === 'issues' ? SelectedTab : TabLink;
    const StatusButton = visiblePage === 'status' ? SelectedTab : TabLink;
    const NavigationButton  = visiblePage === 'navigation' ? SelectedTab : TabLink;
    const MobileButton  = visiblePage === 'mobile' ? SelectedTab : TabLink;
    const BacklogButton  = visiblePage === 'backlog' ? SelectedTab : TabLink;
    const TasksButton  = visiblePage === 'tasks' ? SelectedTab : TabLink;

    function getText(page) {
        if (visiblePage !== page) {
            return React.Fragment;
        }
        if (visiblePage === 'tasks') {
            return <div>In progress, subtasks, bugs or suggestions to or from, and resolve to move to
                Tasks Complete.</div>;
        }
        if (visiblePage === 'solo') {
            return <div>Easily invite participants to view status, review work, and subscribe to jobs of
                interest.</div>
        }
        if (visiblePage === 'status') {
            return <div>See completion, in progress, done, blocked, and bugs, and turn
                approvals off with a view setting.</div>;
        }
        if (visiblePage === 'navigation') {
            return <div>Keystrokes for important buttons including a next button that jumps to what needs your
                attention.</div>;
        }
        if (visiblePage === 'mobile') {
            return <div>No install - just go to the normal URL and use our progressive web app.</div>;
        }
        if (visiblePage === 'backlog') {
            return <div>Three levels of bugs and two of unassigned jobs with built-in rules, display, and
                notification types.</div>;
        }
        if (visiblePage === 'scrum') {
            return <div>Assignment sends an approval request to the team which is resent if an approval expires.</div>;
        }
        if (visiblePage === 'issues') {
            return <div>Questions and suggestions are first class citizens with voting and job assistance.</div>;
        }
        if (visiblePage === 'inbox') {
            return <div style={{maxWidth: '20rem'}}>Dozens of wizards guide notification handling and critical ones
                stay till resolved.</div>;
        }
        if (visiblePage === 'slack') {
            return <div>Don't choose between sync and async or communication and context.</div>;
        }
        return <div>Why use chat and issue trackers with a customer when a private workspace does more?</div>;
    }

    function setVisiblePageAndImage(page) {
        let imageHolder = document.getElementById(`${visiblePage}Image`);
        if (imageHolder) {
            imageHolder.style.display = 'none';
        }
        imageHolder = document.getElementById(`${page}Image`);
        if (imageHolder) {
            imageHolder.style.display = 'block';
        }
        setVisiblePage(page);
    }

    function getNextPageMobile() {
        if (visiblePage === 'tasks') {
            return 'solo';
        }
        if (visiblePage === 'solo') {
            return 'status';
        }
        if (visiblePage === 'status') {
            return 'navigation';
        }
        if (visiblePage === 'navigation') {
            return 'mobile';
        }
        if (visiblePage === 'mobile') {
            return 'scrum';
        }
        if (visiblePage === 'scrum') {
            return 'issues';
        }
        if (visiblePage === 'issues') {
            return 'inbox';
        }
        if (visiblePage === 'inbox') {
            return 'slack';
        }
        if (visiblePage === 'slack') {
            return 'scale';
        }
        if (visiblePage === 'scale') {
            return 'tasks';
        }
    }

    return (
        <div style={{scrollSnapAlign: isMobile ? undefined : 'center', scrollMargin: isMobile ? undefined : '3rem',
            scrollSnapStop: isMobile ? undefined : 'always'}}>
            <ScalableContainer style={{display: isMobile ? 'block' : undefined}}>
                <LeftNav style={{width: isMobile ? '94%' : undefined, marginLeft: isMobile ? 'auto' : undefined,
                    marginRight: isMobile ? 'auto' : '2rem'}}>
                    {(visibleSection === 'solo' || isMobile) && (
                    <>
                        {(visiblePage === 'tasks' || !isMobile) && (
                            <ButtonContainer>
                                <TasksButton onClick={() => setVisiblePageAndImage('tasks')}>Tasks</TasksButton>
                            </ButtonContainer>
                        )}
                        {getText('tasks')}
                        {(visiblePage === 'solo' || !isMobile) && (
                            <ButtonContainer>
                                <SoloButton onClick={() => setVisiblePageAndImage('solo')}>Observers</SoloButton>
                            </ButtonContainer>
                        )}
                        {getText('solo')}
                        {(visiblePage === 'status' || !isMobile) && (
                            <ButtonContainer>
                                <StatusButton onClick={() => setVisiblePageAndImage('status')}>Status</StatusButton>
                            </ButtonContainer>
                        )}
                        {getText('status')}
                        {(visiblePage === 'navigation' || !isMobile) && (
                            <ButtonContainer>
                                <NavigationButton onClick={() => setVisiblePageAndImage('navigation')}>Navigation</NavigationButton>
                            </ButtonContainer>
                        )}
                        {getText('navigation')}
                        {(visiblePage === 'mobile' || !isMobile) && (
                            <ButtonContainer>
                                <MobileButton onClick={() => setVisiblePageAndImage('mobile')}>Mobile</MobileButton>
                            </ButtonContainer>
                        )}
                        {getText('mobile')}
                        {(visiblePage === 'backlog' || !isMobile) && (
                            <ButtonContainer>
                                <BacklogButton onClick={() => setVisiblePageAndImage('backlog')}>Backlog</BacklogButton>
                            </ButtonContainer>
                        )}
                        {getText('backlog')}
                    </>
                    )}
                    {(visibleSection === 'team' || isMobile) && (
                    <>
                        {(visiblePage === 'scrum' || !isMobile) && (
                            <ButtonContainer>
                                <ScrumButton onClick={() => setVisiblePageAndImage('scrum')}>Approval</ScrumButton>
                            </ButtonContainer>
                        )}
                        {getText('scrum')}
                        {(visiblePage === 'issues' || !isMobile) && (
                            <ButtonContainer>
                                <IssuesButton onClick={() => setVisiblePageAndImage('issues')}>Assistance</IssuesButton>
                            </ButtonContainer>
                        )}
                        {getText('issues')}
                        {(visiblePage === 'inbox' || !isMobile) && (
                            <ButtonContainer>
                                <InboxButton onClick={() => setVisiblePageAndImage('inbox')}>Inbox wizards</InboxButton>
                            </ButtonContainer>
                        )}
                        {getText('inbox')}
                        {(visiblePage === 'slack' || !isMobile) && (
                            <ButtonContainer>
                                <SlackButton onClick={() => setVisiblePageAndImage('slack')}>Slack integration</SlackButton>
                            </ButtonContainer>
                        )}
                        {getText('slack')}
                        {(visiblePage === 'scale' || !isMobile) && (
                            <ButtonContainer>
                                <ScaleButton onClick={() => setVisiblePageAndImage('scale')}>Discussion</ScaleButton>
                            </ButtonContainer>
                        )}
                        {getText('scale')}
                    </>
                    )}
                </LeftNav>
                <div style={{minWidth: '50%'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '1rem'}}>
                        {visibleSection === 'solo' && !isMobile && (
                        <>
                        <CTAText>
                            <PrimaryButton as="a" href={primaryButtonUrl}>Sign up</PrimaryButton> for low
                            friction dev specific features.
                        </CTAText>
                        <SecondaryButton as="a" href={'/'}
                                         onClick={(event) => {
                                             event.preventDefault();
                                             setVisibleSection('team');
                                             setVisiblePageAndImage('scrum');
                                         }}>Next</SecondaryButton>
                        </>
                        )}
                        {visibleSection === 'team' && !isMobile && (
                        <>
                            <CTAText>
                                Same free <PrimaryButton as="a" href={primaryButtonUrl}>sign up</PrimaryButton> for
                                asynchronous collaboration.
                            </CTAText>
                            <SecondaryButton as="a" href={'/'}
                                             onClick={(event) => {
                                                 event.preventDefault();
                                                 setVisibleSection('solo');
                                                 setVisiblePageAndImage('tasks');
                                             }}>Next</SecondaryButton>
                        </>
                        )}
                    </div>
                    <RightPageContents style={{width: isMobile ? '94%' : undefined,
                        marginBottom: isMobile ? '3rem' : undefined,
                        marginLeft: isMobile ? 'auto' : undefined,
                        marginRight: isMobile ? 'auto' : undefined}}>
                        {isMobile && (
                            <SecondaryButton as="a" href={'/'} style={{marginBottom: '2rem'}}
                                             onClick={(event) => {
                                                 event.preventDefault();
                                                 setVisiblePageAndImage(getNextPageMobile());
                                             }}>Next</SecondaryButton>
                        )}
                        <img src={isMobile ? TasksMobile : Tasks}
                             style={{maxHeight: isMobile ? '37rem' : undefined}}
                             alt='Tasks' id='tasksImage' />
                        <img src={isMobile ? ProgressMobile : Progress} alt='Progress' id='soloImage'
                             style={{display: 'none'}} />
                        <img src={isMobile ? StatusMobile : StatusAutonomous} alt='Status' id='statusImage'
                             style={{display: 'none', maxHeight: isMobile ? '35rem' : undefined,
                                 float: isMobile ? undefined : 'right'}}/>
                        <img src={Navigate} alt='Inbox' id='navigationImage' style={{display: 'none',
                            marginLeft: '2rem'}} />
                        <img src={Mobile} alt='Mobile' id='mobileImage' style={{display: 'none',
                            maxHeight: isMobile ? '30rem' : '40rem',
                            marginLeft: isMobile ? undefined : 'auto', marginRight: isMobile ? undefined : 'auto'}} />
                        <img src={Bugs} alt='Bugs' id='backlogImage'
                             style={{display: 'none', float: 'right'}} />
                        <img src={isMobile ? JobApprovalMobile : ApprovalsLarge} alt='Job approval' id='scrumImage'
                             style={{display: 'none', marginLeft: 'auto', marginRight: 'auto'}}/>
                        <img src={isMobile ? QuestionMobile : Question} alt='Question' id='issuesImage'
                             style={{display: 'none', maxHeight: isMobile ? '35rem' : '40rem'}} />
                        <img src={isMobile ? AssignJobMobile : AssignJob} alt='Assign job' id='inboxImage'
                             style={{display: 'none'}}/>
                        <img src={Unfurl}  alt='Slack integration' id='slackImage'
                             style={{display: 'none', marginLeft: 'auto', marginRight: 'auto'}}/>
                        <img src={isMobile ? BugsMobile : Discussion} alt='Bugs' id='scaleImage'
                             style={{display: 'none', float: 'right'}}/>
                    </RightPageContents>
                </div>
            </ScalableContainer>
        </div>
    );
};

export default BelowTheFold;