import React from 'react';
import Header from '../../../shared/SiteHeader';
import AboveTheFold from "../../../shared/AboveTheFold";
import Pricing from "../../../shared/Pricing";
import Footer from "../../../shared/MiniCenteredFooter";
import tw from "twin.macro";
import BelowTheFold from "../../../shared/BelowTheFold";

const Container = tw.div`bg-blue-200`;

function MobileHome() {
    return (
        <>
            <Header />
            <Container>
                <AboveTheFold isMobile/>
                <div style={{marginTop: '3rem'}}/>
                <BelowTheFold isMobile />
                <Pricing/>
            </Container>
            <Footer/>
        </>
    );
}

export default MobileHome;