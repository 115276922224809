import React, {useState} from "react";
import tw from "twin.macro";
import "./bounce.css";
import DemoTeaser from "./DemoTeaser";
import SingleTeaser from "./SingleTeaser";
import CustomerTeaser from "./CustomerTeaser";
const Container = tw.div`pt-20 w-full flex justify-center items-center`;
const TextContent = tw.div`text-center md:text-left mr-5 ml-5`;
const Heading = tw.div`font-black text-left text-4xl lg:text-6xl text-center leading-tight tracking-wide mt-4`;
const HeadingHighlight = tw.span`text-primary-500 whitespace-nowrap`;

const AboveTheFold = (properties) => {
    const { isMobile } = properties;
    const [visiblePage, setVisiblePage] = useState('single');

    return (
        <Container>
            <TextContent>
                <Heading>
                    Task communication for <HeadingHighlight>complex making</HeadingHighlight>.
                </Heading>
                {(visiblePage === 'single' || isMobile) && (
                    <SingleTeaser isMobile={isMobile} setVisiblePage={setVisiblePage} />
                )}
                {(visiblePage === 'team' || isMobile) && (
                    <DemoTeaser isMobile={isMobile} setVisiblePage={setVisiblePage} />
                )}
                {(visiblePage === 'customer' || isMobile) && (
                    <CustomerTeaser isMobile={isMobile} setVisiblePage={setVisiblePage} />
                )}
            </TextContent>
        </Container>
    );
};

export default AboveTheFold;