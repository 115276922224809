import React from "react";
import videoMobile from '../assets/singleUserModeMobile.mp4';
import tw from "twin.macro";

const CTA = tw.div`text-center font-normal text-xl`;
const PrimaryButtonBase = tw.button`px-1 py-1 text-primary-500 border-3 border-primary-500 border-solid hover:bg-primary-500 hover:text-gray-100 focus:outline-none bg-white`;
const PrimaryButton = tw(
    PrimaryButtonBase,
)`mt-2 mb-1 text-xl font-bold inline-block rounded-xl`;
const primaryButtonUrl = "https://production.uclusion.com/?utm_campaign=team#signup";
const SecondaryButton = tw.button`px-1 py-1 mt-2 mb-1 border-3 border-primary-500 border-solid text-xl font-bold inline-block rounded-xl hover:bg-primary-500 hover:text-gray-100 bg-white`;

const SingleTeaser = (properties) => {
    const { isMobile, setVisiblePage } = properties;

    if (isMobile) {
        return (
            <>
                <CTA style={{fontSize: '1rem', marginTop: '3rem'}}>
                    <PrimaryButton as="a" href={primaryButtonUrl}>Signup</PrimaryButton> for single subscriber free
                    forever.
                </CTA>
                <video id='aboveTheFoldVideo' style={{borderRadius: '15px',
                    marginLeft: 'auto', marginRight: 'auto'}} autoPlay muted loop controls
                >
                    <source
                        src={videoMobile}
                        type="video/mp4"
                    />
                    Your browser does not support the video tag.
                </video>
            </>
        );
    }
    return (
        <>
            <CTA style={{marginTop: '2rem'}}>
                <PrimaryButton as="a" href={primaryButtonUrl}>Signup</PrimaryButton> for organized <b>single coding</b> free
                forever and share status and get feedback also. <SecondaryButton as="a" href={'/'} style={{float: 'right', marginRight: '4rem'}}
                                          onClick={(event) => {
                                              event.preventDefault();
                                              setVisiblePage('team');
                                          }}>Next</SecondaryButton>
            </CTA>
            <video id='aboveTheFoldVideo' style={{marginLeft: 'auto', marginRight: 'auto', borderRadius: '15px'}}
                   width="1200" autoPlay muted loop controls>
                <source
                    src={videoMobile}
                    type="video/mp4"
                />
                Your browser does not support the video tag.
            </video>
        </>
    );
};

export default SingleTeaser;